import React, { Component } from "react";
import "./Navbar.css";

class Navbar extends Component {
  render() {
    return (
      <div className="navbar">
        <h1 className="header">CV Generator</h1>
      </div>
    );
  }
}

export default Navbar;
