const Icons = {
  maps: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADQElEQVRoge2ZS0gWURTHfz7K7KGbpAcIYQq9FhkWlRElUglBUZQukkgIKQhq0yKsZbta6DKsVauKAikre0AQPajUsgf02PQw20hkppXaYu7XnBnnc+583/ERfT+4cJk553/OnXvn3rl3IEWK/5s0Ra0ZQDlQASwBZpkC0GVKB9AMtAA9irGTYh5wGugHhixLP9BofMeNqUAD0RIPakg9kD3GuVMItPmSGQDuA3XAGqAAmGZKgbl21NgM+nzbgPljlXwZ0O1LoBkojqCxDLjq0+gG1qtmGkAp8F0E7QF2JKG3M0BvVZI5xmUu0CmCfSTaU49HsdGK6X4CZivoDuOiCNILLFfUXorz9GP65xS1AViHd7xWagcAqnwx1mqKXxHCLZrCPm6KOJe1ROcAv4XwSi3hAFbgTrEDOO9d0uzFTb5VQzAEub7UhBmnWwiuFvVrERIpAmpNKYzgd13USyP4xeUB7hPZYOlTA/QJvz5gj6XvJuF3L1KmcegSggss7IvwJi8bYfO5sEj4dIYZ2wyhHFH/YmFfDmQFXM8y98KQMXLiWhlsGpAh6jbf8EMWNiPxTdQnJakFwAfcLs23sC8keAj9wG4I5Quf92HGNj0gx6HNbPIG2IfTiBh9wH7grYW/jBH6DmRaCD4BSkx9I3DbwucMcAd3zN/ALnnwznSPLX1GZBtul75Cdx/tJw14LuJt1RDNxTumKzRE41CO950JnYVsaRTCNkMoUZpFnFOawovx7mO3aIobyoT+IM6CpsolEeAduicJmcAzoX9BUfsvBTg7sViQk4raR4RuL6N4XnQMbzdvVtAsAX4K3ToFzbhkAe0i2GecDU+i5AKvhV47wd9RqhQBX0XQRzindFHJAJqETg+wUCnHUCpF4NgpQtQFrt6nUa2ZoA0NvgSOR/A96POtV8/OgnSc6U4mctjCbzfeNaUJ7yf7mJIN3MU7M9WOYL8d7wnHQ5zD33FlJs6PC3lKHbT/rcI7XXYY3wlBHvAUb08cEPd3Ab/E/ZckN/2OCkGNOISzuRkQ118wSoe3GuQx/MeHLK3GZkIzneE/L4aAWzgr7z/BZOAsbvLngSnjmlECpAMnTLE5PEiRIkUC/AFLxDPceeCF8QAAAABJRU5ErkJggg==",
  tel: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAClklEQVRoge2YO2gUURRAz0Zl4yeLJJUgEVRUUDCFiUUgfglaiYUoVkZBQbAREQUrmzR2aqG2opWVjfhB0UoNoiGNhRBFxA+iZlGIn43FdZz7xvkzu3eFOfDgsu/t23Nn9s28d6GkpKSkXekHJoAGMBPRpoAjVoJJTBAtrtsPoMfIMZZp0iUwA+wwcqQjpm9KxfOASqCdUf3ri1dLR1wCX1TcHdL/WMUDxehkJy6BtypeGtL/SMX9CXM1jbgfHVfxupD+SeD9n7gGrCrIKRNxCTxT8WDEmDEVm6yDuAQeqngYqIaM+azixYUYFUgFeIX/qNwW6D+F+yg90FK7lJzFF7ysPj+JK38DmNNyuxQM4Ut+AxYCx3HlbwJzrQTT8BRf9h6u/G3aXB5ghPDtw31ggaFXajqB17jyd5HtxX/Dfnz5BrDCVic7HcAT/CSu2+rkYwvu4WaXrU4+LuIn8AlYYquTnRru2/kBMNvUKAcbkGOkl8R5W518HMV9rB621cnHVfwEfgK7bXWyUwXu4CfxHdiZY55lwDHgELAWmFWUYBq6kLOxLrHsy/D9YeAr7t+xjuy5RpFqR9NLNj24STSA0ySfkTciO9ykks00sLcJ3g5duH8n720ddfUGkSvtjX0B3ELeLWFJvGmi+1+qwJWQH94eGDeAlGy8MZNAr+pfDuxB6k56rpZxEFnQwbvRiyzSj+rzd8DKmLlMEgA5yb0MCNRx5T8AqxPmMUsAZNtxgfDq9i9kESdhmoDHZqTGFEyiDlwCNhG9n2qLBEAeqSP8e7LTO9trwAlgK7Iu5tNGCXh0IonoQkGa1rCQTWIIOIe7PY9q4xFzUGm6ZjIVoA95P/QBa4BFSA2qBjxH7tpY1AQlJSUldvwG9GUBEopSyoEAAAAASUVORK5CYII=",
  mail: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAABzElEQVRoge3YPWgUQRjG8V8kFiJ+IOJHULCxsxVsYmVnwMa0Ke2s7Y2FbUoLLVJaiCBY2Fnaio02FvFygoiBNIKaWOwtTDY77u7t3u6B84eBvd1n3nuenb3ZuSGRSCQSiYMs4hG2sT8nbRvrE2+VrM+B4Vh7WCfAeA6Mxtq4ToCww3tcr9NpRlzDu4KnSoqpf2EDx2fjsZSjeICfJX4qCcW7wfFHLM/AbJEb+BDx0DjAFbwJPu/hCU50bBqO4TF+B9/3Fle1CJCziu/B+RHudGQcbspGOK+/g3tY+IenKDHxBbwoXH+Osy2Mn5KN6F5Q8zUu1/RUSpV4Fd8CzVesNTQOK9gK6vyQ3fVpPDUWn8NmQfsKl2rUPx/pu9TS01TiFXxx+C4uRPRlo3e3Y0/NxDgte47DfvnskbOElwVNk9/PTAPk3Hbwmd7F/UkL5/KtibYJvQSAk7K39p9CnfwdsokzU9TtLUDOMj4FdT7jVot6vQcgWztt6GYdFfVUNlvsV1wfgqinIz0b6ZwUYGhSgKFJAYYmBRiaFGBoyjZLx7g4OW67oOuaUfFE2Qg87cHItDyrI1qU7VCPHP5TMlQbyXama22vJxKJROL/4S9bmVWpDxxKzwAAAABJRU5ErkJggg==",
  github:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAEJklEQVRoge3ZS2heRRQH8J8xIalpah82VolWqa9INYIKaqoixUerBRFB8LURxSeRom4VN+JGLBFcuXHlRtGFz6ogqPjoopHWapoarVVbkJpYm/pI/FzM/ezNzfeY+73SRf5wyM29M+ec/8yZmTPnYwHHFo5roK5luBqDOB9r0Ivu5PthHMB3+Bof4yNMNNCHmtGJO/AuplHIKdN4G7cnulqORdiMnyKcjZV9eBRdrSKxAWMNJJCV3bi+mQQ6saWJBLLysjDzDcXJ2NZCEkX5QtgwGoIzhOluNYmijCY+1IWV+GYeSRRlDKtqJdFlfsKpUpjVtEW/eAw4n5XhvCQ2VFH4hBB2l+BJfF+Hc7vwOAaE7OC5Ku2viyWxSPVzoi/TpyshdATvYwjrcLaQonTjnOTdg3gDh/AIjs/oGqhie1RkiG2uoqig/OnbEWOgSttTIuwPVVPeKS7taPhBlcKqCPs/ysxKW0bJrTg1wtjqOp2thDURbfpwS/pFlsjdkcYujGxXCy6KbHdX+p80kWVYH6lkbWS7WjAQ2e5aLCn14WZx2+WksCCbhX78HenLpmKn9IysizT0En6p39+y2IXXItteWXxIE+mP7PxKrEd14NXIdueVejmq+lT+g/a63ayOcyN8KQgJLWbPyPIIA5PCPbvZmIpst6L4kB7dnoiOPULlpZDDqVpwYup5WkhnYKnZlZ//fU4TKTa4Br8lnSeEdKRlBYEEO1L+tDvqcNGf5UKxomQ5a0IY6c+wHXvwq7BD7UnJbc3xfRaGlV8XhxO/CjhY7JBm9K2QoX6IESE1Hxdm5/dEJh0l3Ey0CeHVg8WJrBRSo9OFQ/MGYbH3Mzu0xhMinwsVwTOFMOtNKVua/L1XqHI0A8OJ/ilh0I4kz/vxA/ZiZ0JkvJSCp8Vfhv4Qf4DmwX34N4cfT5VSsj6HgoIwSvdoTP24E8/mJFEQImYOTsBfORUV8KmQ82RvejHoFgZjTw12/5S6F2VH8x2ly5V3Jp2ewUllnDogXHM/ERbhXmF3mUy+r3B0wV6Aq4QRXRzDuATewo3lPm5SfssbFO7g+8u0yYbdY0KFsoje5N1URP8Y2ViJZZvw+0WpjiPJ90uFrbgSicEKNgYbQGa3uZfCORiqoKA4lWuFMDwkrKuDQihtxU3VDOD+Ook8FGFDB74qo+DNGAURaKtgo5pslyMDvwIzJZTMaNz58UANJGZwWV5DL5RRNiEU1vpExGkFnFUDkS21GOrABzkN5TlLOnLqfk++AuAsLJEvlvMiVu9OIc+rC6uFn5Pni8gOnFYXgxR6hJO01US2asBMZNEhpNildrNGE5nB85pc6LgYX5ZxIC9K6RjB5Q3xNALtwhac/h1lXw16fk71H8PDWlNumoM2IW15XYVMtAI2JX03qu9MWsACmoX/ALeEN4NrGxnlAAAAAElFTkSuQmCC",
  linkedin:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACz0lEQVRoge2aXUtUQRjHf2Z0lEiS0gKVhLqpr1DpjRiWH6DNb7AQhR8juigKotvoG7QaRjeSgVnXuq5LF21dlF1IL5C1uF08Z+A0zDk7c94N//Cgzpzz8P85c2bnPLNwoHKpJ8Vcg8AkcBG4AJwFhoGjfv9P4DPwHlgHVoBlYCdFD7HlAXPAEtAGOo7RBp4DN/xcuasfmAc+WZi1jY/AbaAvL4gZoJkigB5bwJUsATzgfoYAejxBRj5VnQLe5QihYg1ZMFLRODLceUOoaPgeEmkIqBcIoaIJnI4L0Ucx0ylqmsVaoh+VwLweD1whZkpgOiymbSH6sfucqAEjwCiwmCNIA8spNm+ZcDRwz1iOIB3gVjcID/ttR5EgLbqMypxDskUfZgzZ+OUJ0gEqUSBLBRiKGwtB48H3kUFgG+iNIi2R2sAJ4BvAoUDHJPsHAuAwMKH+CIJcckzUo4VN/wpwHVm2PeQZq/jtcTRhaqzhNkd1deuvdjFVBf44enhmStTIGMRGVUcPdVOSryUAAZlmth62TQl2cwCZRXaxP/yf1wzXVBw8/CoCZDYkz1XtupGkIFlPrbched5o1/U6eDBOrc2MQcJG3PRftfWwoW4Ifo40TXQp6khIe5LC3Jb6JQiyniBhUTKOyOsCjCTVK1Pjcdzqt7pc++Neq+I3MKBuCI7IDvDSRFhSvcDf+cK/ICBlyv2ip1GdHlIVL/vUaqGtgvqI7AJ3I0CTGE+SS9cd5BmJlEc5yqRhYV0OAjmfKNqwKfaAKVsIpYclMK7HPVcIkOFbK4F5FauEb3O66iTleF6ayGFTIo3j/hqcZmwCZ5JCKA1TzDRbRQ6bUpU6DN3LCeIxGZ+9T5PtVKsTY4mNKw8p7bdSBPgA3KSgb0B4SNVjAffiWse/p4ZUH2MvrZDul2oGkPrxZeA8cA55UI/5/d+BL8hSuoG8FC377Qf67/QXu4F46BKPyR0AAAAASUVORK5CYII=",
  twitter:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAEs0lEQVRoge3ZaahVVRQH8J9Dg0MpDmGDJQ1WaibRPFIWFQWVzfSlKCL7kBTZAA0EGTQQJo2IRUhFE1EQlQRl0UBagQ1mKmZZYEZZqTn2+rD26Zx3373v3eE8H9H7w+Hec/ba/73Wntbaa9OLXvTif4E+Pdz+WByFURiEn/ANPsC2HtSrLuyAa/E12mo8P+M+DKvB0RcX4w2cXU1gMp7BHiUqXsQkLJErvAbPCaXvxBNYWCj/DZcU6o/E1RUcD1U2siNWpMJFGFqyEWdhXeL/EuegXw3ZffGUmF5twtiPCu/FZ1Jl5fMrBN7D4JKMOAzrE+8TYnrVg1OxtqDTNryL39P7C9UqzU2Fj+Pj9P8TjGhW+4Sd5SP9SBP1x2GGWBO74zG5YXPwoRixf5HNu+MxRG7MEoxvxoKE6YlnIfq3wDMC81TfHJYWBf9MH3dL74Pxavq2Hlc20Xgf/Jg4JjdRv4hsxmTPlsLv8UXBjalgUOFbX9yDv1PZPBzYQONHpXrLm9O9HSZjNqbiFrkhUysFf0gFB9cgWZnKN4ktb+86Gr8+1Xm4CcVrYRq2is69tZrA66nRC2oQDMEsbE5ym8VwnyJGrhruT7I3Nqt1BR4stH15LaHrktBrXZAdgOdFr2TzdRUeFTvLqIJstsNc05zeHbAq8Z3bmdAw4bC2qG8d7IN7sVrHXWSNiJe+Su8zmlS8Et8nvtFdCc6Qb5U71kneDyeKYf9MPvWKz+yGVa6OXxLf8MqCYvQ7B7uK0GEH4WSuEIu7EQzARBFmjBLb+dMiqm0Vm0QH7yQ6rAMGqe5o5mu/HfckdhM6/dqZUB/5lFijvTFTulnBenGM0GdBtcIsZGgT54NDcYOIsSZgoK53se2FLExa2qkUHhAGvdSt6jSPp4R+07oSPER4yy3Yv5uVagbLhSGH1yP8ojymquWtewIT5Ou3rgh6T/lin6nnkxMZ7pCfP+rGifgjVXxW+UfeRtEXy4Q+ZzZaeZL8HLEat4nUTU/g7KTHCrXP+J1WzgwpPrNKVLBevJvant6ZUK0FfYSOKaE2bGhZrcZwOk4S3rypeG2sPPVyF44V55Htif74POlwUytEmQNail1a16th3JzaXykC0aYxTB7/v2X7jsg4/JXaPqMMwolifmbZwQllkHaBXbE4tflkmcTj8G0i3iqye1Owl9byVNXQT56C+kJ5mc5/MRB3yx1l9mxUO1nRKPqKA1iWvO7WeG+IfBPIcrEXlsDbv8C7DieUwFkV/XAa3pYbsUyEM61iqDwVugEnl8DZDqNFhn6m9l5+rUhSDCyhjSPFWT4LhY5thexlcUO0XNyLrBI9UxmeLBOpyjK24QFizWVpz0UY0yrpAh2V3ih2jZdEjHNQq40k9MdV8vTsNnEybcnhZRggerp4ofImzlP/hUxX2BO3y+9J2vApjiuJvx1GiOR05lHbxJSbi8uE36gXA3C0CP/f1z69uhgXKfnQVo1sKC4VSeIjK8rWJkW+Ez5lbfo+SMRjI8U0HKN9ZL0Jr4gI9h1hUKnoqlfGi0vMk4VRta6LK7FZRAPzRZz2jvAP3YZGh3dv7Ce25uHy/PB6ceP1m7iqWy6mUy968V/HP0/gbvE5/VKdAAAAAElFTkSuQmCC",
  portfolio:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABDUlEQVR4nO2WQUrDQBSGv0qW7QHsLaygaL1GTtAD2GXVbc1a3Np9qJteogXFW9h2q1AXCgotA68Q9KWk7eQRZH74YUjyz/8tZsiDoKDtNQB+gOUvu2cP7KkGcAOkwGOOtfIsRF7O7XktHaoi4GXD5r78LF1/dGFQvva5BhAbAsT/AqAPHAJNILEGSJX80BKgo+Q7lgC3Sj6xBFgAJ5nsKfBhfQi/gTEwkbXpIXwFRsC92K2nZQN8AndAC6gp+Zq8c0BfvgHegCOK6xh49wnQY3td+QS43AGg6xNgJn+wgwLF7ps2MC/7Gi73cDUB2oYAZxpAJONS2eVPeSOZU10Gx01D6a5O5Wq6jqCg6mgFIhjoiIBk+FEAAAAASUVORK5CYII=",
};

export default Icons;
